import React, { useEffect, useState, useCallback } from 'react';
import CheckBox from '../../common/CheckBox';
import { Loader } from '../../common/Loader';
import useApiCalls from '../../utility/APICalls';

const Age = ({ setAge }) => {
  const [selectedAge, setSelectedAge] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checkBoxData, setData] = useState([]);

  useEffect(() => {
    const selectedValues = checkBoxData
      .filter((_, i) => selectedAge[i])
      .map((item) => item.value)
      .join(', ');

    setAge(selectedValues);
  }, [selectedAge, setAge, checkBoxData]);

  const handleCheckboxChange = (index) => {
    const updatedAge = [...selectedAge];
    updatedAge[index] = !updatedAge[index];
    setSelectedAge(updatedAge);
  };

  const apicalls = useApiCalls();
  const callApi = useCallback(
    async (endpoint) => {
      setIsLoading(true);
      if (endpoint) {
        try {
          const response = await apicalls(endpoint, 'GET');
          if (response.status === 200 && response.data.data) {
            console.log('Age response.data', response.data.data);
            const formattedData = response.data.data.map((item) => ({
              id: item.id,
              value: item.value,
            }));
            setData(formattedData);
            setSelectedAge(Array(formattedData.length).fill(false)); // Initialize selectedAge based on fetched data length
          }
        } catch (error) {
          console.error('API Call Error:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setData([]);
        setIsLoading(false);
      }
    },
    [apicalls],
  );

  useEffect(() => {
    if (checkBoxData.length <= 0) {
      callApi('api/agegroup');
    }
  }, [checkBoxData.length, callApi]);

  return (
    <div className="flex flex-col space-y-2 justify-start items-start">
      {isLoading ? (
        <Loader />
      ) : (
        checkBoxData.length > 0 &&
        checkBoxData.map((item, index) => (
          <CheckBox
            key={item.id}
            title={item.value}
            index={index}
            isChecked={selectedAge[index]}
            onCheckboxChange={() => handleCheckboxChange(index)}
          />
        ))
      )}
    </div>
  );
};

export default Age;
