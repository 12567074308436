import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// import {useState,useEffect} from 'react';
import Navbar from './components/Navbar/Navbar';
import Login from './pages/Auth/Login';
import Signup from './pages/Auth/Signup';
import BillingHistory from './pages/Billing/BillingHistory';
import Blogs from './pages/Blogs/Blogs';
import Campaigns from './pages/Campaigns/Campaigns';
import EditCampaign from './pages/Campaigns/EditCampaign';
import NewCampaign from './pages/Campaigns/NewCampaign';
import Contact from './pages/Contact/Contact';
// import Dashboard from './pages/Dashboard/Dashboard';
import PreReleaseDashBoard from './pages/Dashboard/PreReleaseDashBoard';
import Error505 from './pages/Error/Error505';
import PreReleaseHome from './pages/Home/PreReleaseHome';
// import Home from './pages/Home/Home';
import Profile from './pages/Profile/Profile';
import Payment from './pages/payment/Payment';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        {/* <Navbar showLogo={true} showSideBarIconOnMobile={false} /> */}
        <PreReleaseHome />
      </>
    ),
  },
  {
    path: '/login',
    element: (
      <>
        <Navbar showLogo={true} showSideBarIconOnMobile={false} />
        <Login />
      </>
    ),
  },
  {
    path: '/signup',
    element: (
      <>
        <Navbar showLogo={true} showSideBarIconOnMobile={false} />
        <Signup />
      </>
    ),
  },
  {
    path: '/payment',
    element: (
      <>
        <Navbar showLogo={true} showSideBarIconOnMobile={false} />
        <Payment />
      </>
    ),
  },
  {
    path: '/contact',
    element: (
      <>
        <Navbar showLogo={true} showSideBarIconOnMobile={false} />
        <Contact />
      </>
    ),
  },
  { path: '/Profile', element: <Profile /> },
  { path: '/blogs', element: <Blogs /> },
  { path: '/dashboard', element: <PreReleaseDashBoard /> },
  // { path: '/dashboard', element: <Dashboard /> },
  { path: '/campaigns', element: <Campaigns /> },
  { path: '/campaign/new', element: <NewCampaign /> },
  { path: '/campaign/Edit/:name', element: <EditCampaign /> },
  { path: '/billings', element: <BillingHistory /> },
  { path: '/Error/page/505', element: <Error505 /> },
]);

// console.log('REACT_APP_BASE_URL', process.env.REACT_APP_BASE_URL);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
