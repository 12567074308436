import React, { useEffect, useState } from 'react';
import MultiSelect from '../../common/MultiSelect';
// import useApiCalls from '../../utility/APICalls';

const Location = ({ setLocation }) => {
  const [selectedLocations, setSelectedLocations] = useState({});
  // const [isLoading, setIsLoading] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  // const [dataset,setDataset] = useState([]);

  useEffect(() => {
    const selectedValues = Object.values(selectedLocations).filter(
      (item) => item.selected === true,
    );
    console.log('selectedValues', selectedValues);
    setLocation(selectedValues);
  }, [setLocation, selectedLocations]);

  return (
    <MultiSelect
      title="Select Locations"
      callBackFunction={setSelectedLocations}
      endpoint="api/location"
    />
  );
};

export default Location;
