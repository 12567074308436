import React, { useEffect, useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdClear } from 'react-icons/md';
import './DragNdrop.css';
import PopUpAlert from '../Alerts/PopUpAlert';

const DragNdrop = ({
  onFilesSelected,
  width,
  height,
  adFormat,
  title = 'Drag and drop your Advertisement file here',
  download_file_url = '',
  formattedAd = false,
}) => {
  const [file, setFile] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [acceptedTypes, setAcceptedTypes] = useState('');

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      if (acceptedTypes.includes(selectedFiles[0].type)) {
        setFile(selectedFiles[0]);
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles.length > 0) {
      const newFile = droppedFiles[0];

      // Directly update the file without confirmation
      if (acceptedTypes.includes(newFile.type)) {
        setFile(newFile);
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
  };

  const downloadFile = () => {
    const anchor = document.createElement('a');
    anchor.href = download_file_url;
    anchor.download = '';
    anchor.target = '_blank'; // Open in a new tab
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setIsVisible(false);
  };

  useEffect(() => {
    const acceptedType =
      adFormat === 'Inside Cab Video Ads'
        ? ['video/mp4', 'video/mov', 'video/x-matroska', 'video/x-msvideo']
        : ['image/png', 'image/jpeg'];
    setAcceptedTypes(acceptedType);
  }, [adFormat]);

  useEffect(() => {
    if (file) {
      if (formattedAd) {
        onFilesSelected(title.split(' ')[0], file);
      } else {
        onFilesSelected(file);
      }
    } else {
      if (formattedAd) {
        onFilesSelected(title.split(' ')[0], '');
      } else {
        onFilesSelected('');
      }
    }
  }, [file, onFilesSelected, formattedAd, title]);

  return (
    <>
      {isVisible && (
        <PopUpAlert
          title="Invalid File format"
          message="Unsupported file type. Please select a file of the supported type."
          okayText="Okay"
          okayColor="red-600"
          setClickOkay={() => setIsVisible(false)}
          setIsVisible={setIsVisible}
        />
      )}
      <section
        className="drag-drop border-2 border-slate-300 shadow-3 rounded-lg"
        style={{ width: width, height: height }}
      >
        <div
          className={`document-uploader ${file ? 'upload-box active' : 'upload-box'}`}
          onDrop={handleDrop}
          onDragOver={(event) => event.preventDefault()}
        >
          <>
            {!file && (
              <div className="flex justify-center items-center flex-col upload-info border border-dashed mr-2 rounded-lg py-1">
                <AiOutlineCloudUpload />
                <div>
                  <p className="">{title}</p>
                  <p className="text-red-600 text-xs">
                    File Size Limit :{' '}
                    {adFormat === 'Inside Cab Video Ads' ? '15 MB' : '2 MB'}
                    <br />
                    Supported formats:{' '}
                    {adFormat === 'Inside Cab Video Ads'
                      ? '.mp4, .mov, .mkv, .avi'
                      : '.png, .jpg, .jpeg'}
                  </p>
                </div>
              </div>
            )}
            <div className="flex flex-row  justify-center items-center space-x-2 py-1 mr-1">
              {download_file_url && (
                <button
                  className="flex justify-evenly items-center space-x-2 bg-green-500 bg-opacity-20 rounded-md w-8/12 px-1"
                  onClick={downloadFile}
                >
                  <svg
                    class="w-8 h-8 text-gray-800 dark:text-white text-success"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4"
                    />
                  </svg>
                  <h1 className="text-sm text-success">
                    Creative size details
                  </h1>
                </button>
              )}
              <label
                htmlFor="browse"
                type="button"
                className="text-center font-sm text-primary bg-primary bg-opacity-10 px-1 rounded-md"
              >
                Browse file
              </label>
            </div>
            <input
              type="file"
              hidden
              id="browse"
              onChange={handleFileChange}
              accept={
                adFormat === 'Inside Cab Video Ads'
                  ? '.mp4,.mov,.mkv,.avi'
                  : '.png,.jpg,.jpeg'
              }
            />
          </>

          {file && (
            <div className="file-list">
              <div className="file-list__container">
                <div className="file-item">
                  <div className="file-info">
                    <p>{file.name}</p>
                  </div>
                  <div className="file-actions">
                    <MdClear onClick={handleRemoveFile} />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {file && (
            <div className="success-file">
              <AiOutlineCheckCircle style={{ color: "#6DC24B", marginRight: 1 }} />
              <p>1 file selected</p>
            </div>
          )} */}
        </div>
      </section>
    </>
  );
};

export default DragNdrop;
