import React from 'react';
import LoadingSpiral from '../../assets/images/loader.svg';

const Loader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-transparent bg-opacity-0 z-50">
      <div className="absolute inset-0 bg-blue-400 bg-opacity-20 pointer-events-none"></div>
      <h1 className="animate-spin rounded-full border-4 p-2 border-solid border-blue-600 border-t-transparent">
        {' '}
        Loading...{' '}
      </h1>
      {/* <img src={LoadingSpiral} alt='loader' className="animate-spin rounded-full border-4 p-2 border-solid border-blue-600 border-t-transparent" /> */}
    </div>
  );
};

const LoaderWithoutBg = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-transparent bg-opacity-0 z-50">
      <div className="absolute inset-0 bg-blue-400 bg-opacity-20 pointer-events-none"></div>
      <img
        src={LoadingSpiral}
        alt="loader"
        className="animate-spin rounded-full border-4 p-2 border-solid border-blue-600 border-t-transparent"
      />
    </div>
  );
};

export { Loader, LoaderWithoutBg };
