import React from 'react';
import BounceAdLogo from '../../assets/images/logo.png';

const PreReleaseDashBoard = () => {
  return (
    <body className="flex h-screen flex-col justify-center items-center">
      <div className="flex flex-1 flex-col justify-center items-center">
        <div
          className="flex mb-10 space-x-1 justify-center items-center bg-black rounded-xl  px-1 py-0.5
        transform duration-1000 hover:rotate-12 hover:scale-150 transition "
        >
          <img src={BounceAdLogo} alt="logo" />
          <h1
            className="text-center font-medium text-white"
            style={{ fontSize: 46 }}
          >
            BounceAd
          </h1>
        </div>
        <h1 className="text-center" style={{ fontSize: 40 }}>
          Thanks to show your interest
        </h1>
        <h1 className="text-center" style={{ fontSize: 24 }}>
          You'll be notified once we launch our services. Stay tuned!
        </h1>

        <div className="flex flex-row items-center justify-center mt-10 space-x-5 p-5  rounded-full h-16 w-full">
          <button className="transform duration-500  hover:-rotate-45 hover:scale-150 transition rounded-lg">
            <a
              href="https://www.instagram.com/bouncead_"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                class="w-12 h-12 text-[#b81fab] dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#b81fab"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </button>

          {/* <button className="transform duration-500  hover:origin-top-left hover:rotate-12 hover:scale-150 transition ">
            <svg
              class="w-12 h-12 text-[#b81f1f] dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z"
                clip-rule="evenodd"
              />
            </svg>
          </button> */}

          <button className="transform duration-500  hover:origin-bottom hover:rotate-45 hover:scale-150 transition ">
            <a
              href="https://www.linkedin.com/company/bouncead"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                class="w-12 h-12 text-[#1661ab] dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                  clip-rule="evenodd"
                />
                <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
              </svg>
            </a>
          </button>
        </div>
        <h1 className="text-center mt-5" style={{ fontSize: 16 }}>
          Follow us for more information
        </h1>

        <h1 className="text-center mt-5" style={{ fontSize: 16 }}>
          Or Contact us at
        </h1>
        <button className="transform duration-500 hover:scale-150  transition ">
          <svg
            class="w-12 h-12 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 16v-5.5A3.5 3.5 0 0 0 7.5 7m3.5 9H4v-5.5A3.5 3.5 0 0 1 7.5 7m3.5 9v4M7.5 7H14m0 0V4h2.5M14 7v3m-3.5 6H20v-6a3 3 0 0 0-3-3m-2 9v4m-8-6.5h1"
            />
          </svg>
        </button>
      </div>
    </body>
  );
};

export default PreReleaseDashBoard;
