import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BounceAdLogo from '../../assets/images/logo.png';

const PreReleaseHome = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/signup');
  };
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);
  return (
    <div
      className=" h-screen w-full flex items-center justify-center bg-cover bg-center text-center px-5"
      //   style={{
      //     backgroundImage: 'url(https://images.pexels.com/photos/260689/pexels-photo-260689.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500)',
      //   }}
    >
      <div className="top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-75"></div>
      <div className="z-50 flex flex-col justify-center text-black w-full h-screen">
        <div className="flex justify-center items-center text-center self-center mb-10 bg-black rounded-lg px-1">
          <img
            src={BounceAdLogo}
            alt="logo"
            style={{ width: 50, height: 50 }}
          />
          <b className="text-3xl text-white">BounceAd</b>
        </div>
        <h1 className="text-5xl ">
          We are <b>Almost</b> there!
        </h1>
        <p>Stay tuned for something amazing!!!</p>

        <div className="mt-10 mb-5">
          <div className="shadow w-full bg-slate-300 mt-2 max-w-2xl mx-auto rounded-full">
            <div
              className="rounded-full bg-blue-600 text-xs leading-none text-center text-white py-1"
              style={{ width: '76%' }}
            >
              75%
            </div>
          </div>
        </div>
        <div className="self-center">
          <button
            className="flex justify-center items-center bg-blue-600 rounded-lg w-36  h-12 mt-10 text-white text-lg"
            onClick={handleClick}
          >
            Register Free
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreReleaseHome;
