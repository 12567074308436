import React, { useState, useEffect, useRef } from 'react';
import useApiCalls from '../utility/APICalls';
import { Loader } from './Loader';

const MultiSelect = ({
  id = 0,
  title,
  dataset,
  callBackFunction,
  endpoint,
}) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const datasetFromAPI = [];
  // const [datasetFromAPI, setDataset] = useState([]);
  console.log('errorMessage', errorMessage);
  const apicalls = useApiCalls();

  const callApi = async () => {
    if (endpoint) {
      setIsLoading(true);
      try {
        const response = await apicalls(endpoint, 'GET');
        const respData = response.data;
        console.log('respData', respData);
        if (response.status === 200) {
          if (respData.data) {
            const formattedData = respData.data.map((item) => ({
              id: item.id,
              value: item.value,
              text: item.value, // Assuming you want to display the name as the option text
              selected: false, // Initially, no options are selected
            }));
            console.log(formattedData);
            setOptions(formattedData); // Update options state with API data
          }
        }
      } catch (error) {
        console.log('error', error);
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setOptions(dataset); // Fallback to dataset prop if no endpoint is provided
    }
  };

  // Removed useEffect hook that loads options from a <select> element since it's redundant with API fetching logic

  const open = () => {
    // Only call the API if the dropdown is not currently shown and there's no data fetched yet
    if (!show && !options.length > 0) {
      callApi();
    }
    setShow(!show);
  };

  // useEffect(() => {
  //   const loadOptions = () => {
  //     const select = document.getElementById(id);
  //     if (select) {
  //       const newOptions = [];
  //       for (let i = 0; i < select.options.length; i++) {
  //         newOptions.push({
  //           value: select.options[i].value,
  //           text: select.options[i].innerText,
  //           selected: select.options[i].hasAttribute("selected"),
  //           id: select.options[i].id,
  //         });
  //       }
  //       setOptions(newOptions);
  //     }
  //   };

  //   loadOptions();
  // }, [id]);

  useEffect(() => {
    callBackFunction(options);
  }, [options, callBackFunction]);

  const select = (index, event) => {
    const selectedOption = filteredOptions[index];
    const selectedIndex = options.findIndex(
      (opt) => opt.value === selectedOption.value,
    );

    if (selectedIndex !== -1) {
      const newOptions = [...options];

      if (newOptions[selectedIndex].selected) {
        newOptions[selectedIndex].selected = false;
        setSelected(selected.filter((i) => i !== selectedIndex));
      } else {
        newOptions[selectedIndex].selected = true;
        newOptions[selectedIndex].element = event.currentTarget;
        setSelected([...selected, selectedIndex]);
      }

      setOptions(newOptions);
    }
  };

  const remove = (index) => {
    const newOptions = [...options];
    const selectedIndex = selected.indexOf(index);

    if (selectedIndex !== -1) {
      newOptions[index].selected = false;
      setSelected(selected.filter((i) => i !== index));
      setOptions(newOptions);
    }
  };

  const selectedValues = () => {
    return selected.map((option) => options[option].value);
  };

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdownRef.current) return;
      if (
        !show ||
        dropdownRef.current.contains(target) ||
        triggerRef.current.contains(target)
      )
        return;
      setShow(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [show]);

  const filteredOptions = options.filter(
    (option) =>
      search.trim() === '' ||
      option.text.toLowerCase().includes(search.toLowerCase()),
  );

  const isOpen = () => {
    return show === true;
  };

  return (
    <div className="relative z-50">
      <label className="mb-3 block text-sm font-medium text-black dark:text-white">
        {title}
      </label>
      <div>
        <select className="hidden" id={id}>
          {datasetFromAPI.map((item, index) => (
            <option key={index} value={item.value} id={item.id}>
              {item.value}
            </option>
          ))}
        </select>

        {!isLoading ? (
          <div className="flex flex-col items-center">
            <input
              name="values"
              type="hidden"
              defaultValue={selectedValues()}
            />
            <div className="relative z-20 inline-block w-full">
              <div className="relative flex flex-col items-center">
                <div ref={triggerRef} onClick={open} className="w-full">
                  <div className="mb-2 flex rounded border border-stroke py-2 pl-3 pr-3 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input">
                    <div className="flex flex-auto flex-wrap gap-3">
                      {selected.map((index) =>
                        options[index] ? (
                          <div
                            key={index}
                            className="my-1.5 flex items-center justify-center rounded border-[.5px] border-stroke bg-gray px-2.5 py-1.5 text-sm font-medium dark:border-strokedark dark:bg-white/30"
                          >
                            <div className="max-w-full flex-initial">
                              {options[index].text}
                            </div>
                            <div className="flex flex-auto flex-row-reverse">
                              <div
                                onClick={() => remove(index)}
                                className="cursor-pointer pl-2 hover:text-danger"
                              >
                                <svg
                                  className="fill-current"
                                  role="button"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M9.35355 3.35355C9.54882 3.15829 9.54882 2.84171 9.35355 2.64645C9.15829 2.45118 8.84171 2.45118 8.64645 2.64645L6 5.29289L3.35355 2.64645C3.15829 2.45118 2.84171 2.45118 2.64645 2.64645C2.45118 2.84171 2.45118 3.15829 2.64645 3.35355L5.29289 6L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L6 6.70711L8.64645 9.35355C8.84171 9.54882 9.15829 9.54882 9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645L6.70711 6L9.35355 3.35355Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        ) : null,
                      )}
                      {selected.length === 0 && (
                        <div className="flex-1">
                          <input
                            placeholder="Select an option"
                            className="h-full w-full appearance-none bg-transparent p-1 px-2 outline-none"
                            defaultValue={selectedValues()}
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex w-8 items-center py-1 pl-1 pr-1">
                      <button
                        type="button"
                        onClick={open}
                        className="h-6 w-6 cursor-pointer outline-none focus:outline-none"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                              fill="#637381"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-full px-4">
                  <div
                    className={`max-h-select top-full left-0 z-40 w-full overflow-y-auto rounded bg-white shadow dark:bg-form-input ${
                      isOpen() ? '' : 'hidden'
                    }`}
                    ref={dropdownRef}
                  >
                    <div className="p-2">
                      <input
                        type="text"
                        placeholder="Search..."
                        className="w-full p-2 mb-2 border rounded"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div className="flex w-full flex-col max-h-40 overflow-y-auto">
                      {filteredOptions.map((option, index) => (
                        <div key={index}>
                          <div
                            className="w-full cursor-pointer rounded-t border-b border-stroke hover:bg-primary/5 dark:border-form-strokedark"
                            onClick={(event) => select(index, event)}
                          >
                            <div
                              className={`relative flex w-full items-center border-l-2 border-transparent p-2 pl-2 ${
                                option.selected ? 'border-primary' : ''
                              }`}
                            >
                              <div className="flex w-full items-center">
                                <div className="mx-2 leading-6">
                                  {option.text}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {filteredOptions.length === 0 && (
                        <div className="p-2 text-center text-sm text-gray-500">
                          No options found
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default MultiSelect;
