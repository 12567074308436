import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
// import Alert from '@mui/material/Alert';

export default function useApiCalls() {
  const navigate = useNavigate();
  const logout = async () => {
    try {
      Cookies.remove('token');
      navigate('/login');
    } catch (error) {
      navigate('/Error/page/505');
    }
  };

  const apiCalls = async (
    endpoint = '',
    method,
    data = null,
    headers = {},
    base_url = process.env.REACT_APP_BASE_URL,
    params = {},
  ) => {
    // const apiCalls = async (endpoint = '', method, data = null, headers = {}, base_url = process.env.REACT_APP_BASE_URL, params = {}) => {
    const token = Cookies.get('token');

    if (token) {
      headers['Authorization'] = token;
    }
    try {
      let queryParams = '';
      if (params) {
        queryParams = new URLSearchParams(params).toString();
      }

      const config = {
        method,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
        body: data ? JSON.stringify(data) : undefined,
      };

      const endpointUrl = `${base_url}${endpoint ? '/' + endpoint : ''}${queryParams ? '?' + queryParams : ''}`;

      console.log(`${method} - ${endpointUrl}`);
      console.log('config', config);

      const response = await fetch(endpointUrl, config);
      const responseData = await response.json();

      if (
        response.status === 500 ||
        response.status === 401 ||
        response.status === 400
      ) {
        if (
          [
            'Signature has expired',
            'Session Expired, please re-login',
          ].includes(responseData.msg)
        ) {
          alert(responseData.msg); // Replace with MUI Snackbar or Alert as needed
          await logout();
        }
      }

      return {
        status: response.status,
        data: responseData,
      };
    } catch (error) {
      console.error('API call error:', error);
      navigate('/Error/page/505');
    }
  };

  return apiCalls;
}
